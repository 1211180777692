import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { OfflineLookupField, StyledModal, AnimatedErrorCross, AnimatedSuccessTick } from 'frame/components'
import { trimObjectStrings, useDrillDownModalOnClose } from 'utils'
import { lookup } from 'features/drivers/actions'
import { update } from '../../../actions'
import moment from 'moment'
import { hasRoles } from 'acl'
import { useEnabledFeatureToggleList } from 'features/settings'
import { useIsAlfaDownDisableFeatures } from 'features/drivers'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import KeyValue from 'frame/components/mui-textfield'
import ContractDetails from './contract-details'
import StyledModalV2 from 'frame/components/styled-modal-v2'

const Contract = ({ data, flags, errorMessage, userRoles, onLookup, onUpdate }) => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const featureToggleList = useEnabledFeatureToggleList()
  const userPermissionsFeatureToggle = featureToggleList.includes('UserPermissions')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const disableOutOfHoursFeatureToggle = featureToggleList.includes('OutOfHoursDisabled') // 'ALFA out of hours DISABLE FEATURES' limitations feature is ON
  const isAlfaDownDisableDriverFeatures = useIsAlfaDownDisableFeatures()

  const readOnly = userPermissionsFeatureToggle && hasRoles(['fleetcoordinator'], userRoles)
  const disabled = disableOutOfHoursFeatureToggle ? false : isAlfaDownDisableDriverFeatures //false

  const [originalValues, setOriginalValues] = useState(data)
  const [values, setValues] = useState(data)
  const [saving, setSaving] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const onClose = useDrillDownModalOnClose()

  const onLookupChange = (key) => (option) =>
    setValues({ ...values, [key]: option ? option.value : null })
  
  const onInputChange = ({ target: { id, value } }) =>
    setValues({ ...values, [id]: value })

  const onSave = () => {
    console.log('[ContactDetails onSave]', {
      costCentre: values.costCentre,
      controller: values.controller,
      location: values.location,
      vehicleNotes: values.vehicleNotes,
      driverNote: values.driverNote,
      values,
    })
    setSaving(true)
    onUpdate(values)
  }

  useEffect(() => {
    /**
     * 1. saving will always revert back to false because
     * saving the form is also closing the modal.
     * 2. not sure where the logic on closing the modal but
     * i think upon saving it triggers re-render of the table
     * thats why its also re-rendering the modal.
     */
    if (flags.update === 'processed' || flags.update === 'error') {
      if (flags.update === 'processed') {
        setOriginalValues(values)
      }

      setOpenConfirmationModal(true)
    }
    /**
     * Previous implementation
     */
    // if(saving && (flags.update === 'processed' || flags.update === 'error')) {
    //   flags.update === 'processed' && setOriginalValues(values)
    //   setSaving(false)
    //   setOpenConfirmationModal(true)
    // }
  }, [flags.update, saving])

  const regularInstalmentLabel = () => data.agreementType
    && (data.agreementType.includes('Operating Lease')
      || data.agreementType.includes('Finance Lease')
      || data.agreementType.includes('Vantage Lease'))
     ? 'Regular Instalment (excl. GST)' : 'Regular Instalment'


  const disabledSave = () => {
    if (tfp1944FeatureToggle)
    {
      return originalValues.costCentre === values.costCentre &&
      originalValues.controller === values.controller &&
      originalValues.location === values.location &&
      originalValues.vehicleNotes === values.vehicleNotes &&
      originalValues.driverNote === values.driverNote
    }

    return originalValues.costCentre === values.costCentre &&
    originalValues.controller === values.controller &&
    originalValues.location === values.location &&
    originalValues.vehicleNotes === values.vehicleNotes
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
        }}
      >
        <CardContent
          sx={{
            p: 3,
          }}
        >
          <ContractDetails
            items={[
              {
                label: 'Agreement Type',
                value: data.agreementType,
              },
              {
                label: 'Term',
                value: data.term ?? '-',
              },
              {
                label: 'Start Date',
                value: data.startDate ? moment(data.startDate).format('Do MMMM YYYY') : '-',
              },
              {
                label: 'Maturity Date',
                value: data.maturityDate ? moment(data.maturityDate).format('Do MMMM YYYY') : '-',
              },
              {
                label: `Contract KM's`,
                value: data.contractKm ? parseInt(data.contractKm, 10).toLocaleString('en-NZ') : '-',
              },
              {
                label: regularInstalmentLabel(),
                value: data.regularInstalment ? `$${data.regularInstalment}` : '-',
              }
            ]}
          />

          <Divider sx={{ my: 2, borderColor: theme.palette.accent.sky }} />

          <Stack gap='5px' width='50%'>
            <OfflineLookupField
              fullWidth
              showAllResult
              allowFreeText
              size="small"
              id="costCentre"
              label="Cost Centre"
              hideLabelOnMobile={!mobileView}
              customFleetId={values.thirdPartyNumber}
              lookupPath="drivercostcentre"
              value={values.costCentre || ''}
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              onChange={onLookupChange('costCentre')}
              defaultOptions={values.costCentre ? [{ text: values.costCentre, value: values.costCentre }] : []}
              onLookup={onLookup}
              resourceModel="drivers"
            />

            <OfflineLookupField
              fullWidth
              showAllResult
              allowFreeText
              id="controller"
              size="small"
              label="Fleet Controller"
              hideLabelOnMobile={!mobileView}
              disabled={disabled}
              customFleetId={values.thirdPartyNumber}
              lookupPath="driverfleetcontroller"
              value={values.controller || ''}
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              onChange={onLookupChange('controller')}
              defaultOptions={values.controller ? [{ text: values.controller, value: values.controller }] : []}
              onLookup={onLookup}
              resourceModel="drivers"
            />

            <OfflineLookupField
              fullWidth showAllResult
              id="location"
              size="small"
              disabled={disabled}
              label="Location"
              hideLabelOnMobile={!mobileView}
              lookupPath="location"
              value={values.location || 'Unknown'}
              variant="outlined"
              onChange={onLookupChange('location')}
              defaultOptions={values.location ? [{ text: values.location, value: values.location }] : []}
              onLookup={onLookup}
              resourceModel="drivers"
            />

            <KeyValue
              multiline
              rows={3}
              id="vehicleNotes"
              label='Vehicle Notes'
              disabled={disabled}
              value={values.vehicleNotes || ''}
              inputProps={{ maxLength: 100 }}
              onChange={onInputChange}
            />

            <KeyValue
              id="driverNote"
              label='Driver Note'
              disabled={disabled}
              value={values.driverNote || ''}
              inputProps={{ maxLength: 50 }}
              onChange={onInputChange}
            />
          </Stack>

          <Divider sx={{ mt: 2, mb: 2, borderColor: theme.palette.accent.sky }} />

          <Stack
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent='space-between'
            gap={1}
          >
            <Box width={{ xs: '100%', md: '205px' }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
            {!readOnly && (
              <Box width={{ xs: '100%', md: '205px' }}>
                <Button
                  fullWidth
                  disabled={disabled || disabledSave()}
                  variant="contained"
                  onClick={onSave}
                >
                  Save Changes
                </Button>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>

      <StyledModalV2
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        status={flags.update === 'processed' ? "success" : "error"}
        content={
          <Typography variant="h4" textAlign="center">
            {flags.update === 'processed'
              ? 'Contract details updated successfully.'
              : `Contract details update failed. ${errorMessage}`.trim()
            }
          </Typography>
        }
      />
    </>
  )
}

Contract.propTypes = {
  data: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // func
  onLookup: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = ({ fleet,settings },ownProps) => {

  const agreementNo = ownProps.match.params.id
  let data = fleet.data?.[agreementNo] || null
  return {
    data: trimObjectStrings(data), // Because the API does not clean data for us
    flags: fleet.flags,
    errorMessage: fleet.errorMessage || '',
    userRoles:settings.roles
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLookup: (lookupPath, searchKey, customFleetId) => {
    dispatch(lookup(null, {
      resourceId: lookupPath,
      queryParams: { FleetId: customFleetId, Search: searchKey }
    }))
  },
  onUpdate: (payload) => {
    dispatch(update(payload))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Contract))
