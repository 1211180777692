import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchFooterTicker } from '../actions'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography } from '@material-ui/core'
import { Ribbon } from 'frame/components'
import PhoneIcon from '@material-ui/icons/Phone'

const useStyles = makeStyles(theme => ({
  animatedRibbon: {
    maxHeight: 40, 
  },
  ticker: {
    whiteSpace: 'nowrap',
    minHeight: 22,
  },
  tickerText: {
    fontSize: '15px',
    letterSpacing: 0,
    lineHeight: '22px',
  },
  footerLink: {
    textDecoration: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#000000',
    '&:hover': {
      color: theme.palette.brand,
    }
  },
  footerIcon: {
    position: 'relative',
    top: 2,
    marginRight: 10,
    fontSize: 16,
    color: theme.palette.brand,
  },
  footerSlidingTextSeparator: { margin: '0 100px' },
  footerSeparator: {
    margin: '0 20px', 
    color: theme.palette.brand,
    fontSize: '15px'
  },
  noWordWrap: { whiteSpace: 'nowrap' }
}))

const Footer = ({ flags, selectedFleets, footerTicker, onFetchFooterTicker }) => {

  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  
  const [key, setKey] = useState(1)
  const [delayLoading, setDelayLoading] = useState(true)

  const isDoneFetching = flags?.fetchFooterTicker === 'processed'
    && flags?.checkHaveAppUsers === 'processed'
    && flags?.fetchNotifications === 'processed'
    && flags?.fetchStatusUpdates === 'processed'
    && flags?.fetchIndustryNews === 'processed'
    && flags?.fetchContactDetails === 'processed'
  
  // render ticker after page is fully loaded
  const showTicker = !delayLoading && footerTicker && footerTicker.length > 0

  useEffect(() => {
    onFetchFooterTicker()
  }, [onFetchFooterTicker])

  useEffect(() => {
    isDoneFetching && setTimeout(() => { setDelayLoading(false) }, 1500)
  }, [isDoneFetching])

  const tickerTextConfig = footerTicker.map(({ title, fleets }) =>
    fleets && fleets.length > 0
      ? fleets.every(fleetId => selectedFleets.includes(fleetId)) // check if fleetids in fleets is part of currently selected fleets
        ? title : null
      : title
  )

  // const scrolling = useSpring({
  //   from: { transform: 'translate(60%, 0)' },
  //   to: { transform: 'translate(-60%, 0)' },
  //   config: { duration: 30000 }, // footer sliding text speed
  //   reset: true,
  //   onRest: () => setKey(key + 1)
  // })

  const footerLinksConfig = [
    { label: 'Fleet Enquiry', path: 'https://toyotafleetmanagement.co.nz/toyota-fleet-management-contact' }, 
    { label: 'General Enquiry', path: 'https://toyotafleetmanagement.co.nz/toyota-fleet-management-contact' }, 
    { label: 'Subscribe Here', path: 'http://live.tfs.co.nz/Indicator-Newsletter-Subscribe_Indicator-Newsletter-Subscription-List.html' }, 
    { label: '0800 GO FLEET', path: 'tel:+0800463533', Icon: PhoneIcon, addLineBreak: true }
  ]

  return (
    <>
      <div className={classes.animatedRibbon}>
        <Ribbon
          backgroundColor="#012A46"
          customChildren={
            <div key={key} className={classes.ticker}>
              {showTicker && (
                <div>
                  <Typography variant="body2">
                    {tickerTextConfig.length > 0 && tickerTextConfig.map((text, index) => (
                      <span key={`ticker-${index}`} className={classes.tickerText}>
                        {text}
                        {index !== tickerTextConfig.length - 1 && (
                          <span className={classes.footerSlidingTextSeparator}>|</span>
                        )}
                      </span>
                    ))}
                  </Typography>
                </div>
              )}
            </div>
          }
        />
      </div>
            
      <Ribbon
        backgroundColor="#FFF"
        customChildren={
          <Typography variant="body2">

            {footerLinksConfig.map(({ label, path, Icon, addLineBreak }, index) => (
              <span key={`footer-link-${index}`}>
                {addLineBreak && mobileView && (
                  <br />
                )}
                
                {Icon && (
                  <Icon className={classes.footerIcon} />
                )}

                <a href={path} className={classes.footerLink} rel="noopener noreferrer" target="_blank">
                  {label}
                </a>
                
                {index !== footerLinksConfig.length - 1 && (
                  <span className={classes.footerSeparator}>|</span>
                )}
              </span>
            ))}
          </Typography>
        }
      />
    </>
  )
}

Footer.propTypes = {
  flags: PropTypes.object,
  selectedFleets: PropTypes.array.isRequired,
  footerTicker: PropTypes.array,
  onFetchFooterTicker: PropTypes.func.isRequired,
}

const mapStateToProps = ({ homepage, settings }) => ({
  flags: homepage?.flags,
  footerTicker: homepage?.footerTicker || [],
  selectedFleets: settings.fleet.selected,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchFooterTicker: () => dispatch(fetchFooterTicker()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

